import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from "axios"
import {setUser, updateProjects, updateFiles } from './ducks';
import "./_fontAwesome.jsx"
// import { renderRoutes } from 'react-router-config';
import './App.scss';

import 'react-notifications/lib/notifications.css';
import {NotificationContainer} from 'react-notifications';

const loading = () => <div className="animated fadeIn pt-3 text-center"><div className="sk-spinner sk-spinner-pulse"></div></div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
//const Login = React.lazy(() => import('./views/Pages/Login'));
const Login = React.lazy(() => import('./components/auth/LoginContainer'))
//const Register = React.lazy(() => import('./views/Pages/Register'));
//const Page404 = React.lazy(() => import('./views/Pages/Page404'));
//const Page500 = React.lazy(() => import('./views/Pages/Page500'));

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
    settings: state.settings
  };
};


const actions = {
  setUser, updateProjects, updateFiles
}

class ConnectedApp extends Component {

  state = {
    loading: true
  }

  getUser = () => {
    return axios({
      method: 'get',
      url: '/api/current-user',
    });
  }

  getProjects = () => {
    return axios({
      method: 'get',
      url: '/api/getProjectsByStudent',
    });
  }

  getFiles = () => {
    return axios({
      method: 'get',
      url: '/api/getUsersFiles',
    });
  }

  makeCalls = () => {
    axios.all([this.getUser(), this.getProjects(), this.getFiles()])
      .then(
        axios.spread((user, projects, files) => {
        // console.log("this is the user:", user)
        if (projects?.data.length !== 0){
          // console.log("projects", projects.data);
          this.props.updateProjects(projects.data);
        }
        if(files){
          // console.log("files:", files)
          this.props.updateFiles(files.data);
        }
        if (!Array.isArray(user?.data)){
          // console.log("user", user.data);

          let payload = {
            user : user.data.user,
            is_masquerade : user.data.is_masquerade,
            admin_id : user.data.admin_id
          }
          this.props.setUser(payload);
        } else {
          // console.log("not logged in")
        }
      })).then(response => {
        this.setState({loading: false});
      }).catch(error => {
        // console.log("make calls error", error)
      });
    }



  componentDidMount() {
    this.makeCalls();
  }

  render() {
    if (this.state.loading)
    return null;

    return (
      <BrowserRouter>
          <React.Suspense fallback={loading()}>
            <Switch>
              {/*<Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
              <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />*/}
              <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
              <Route path="/" name="Home" render={props => <DefaultLayout {...props}/>} />


            </Switch>
          </React.Suspense>

          <NotificationContainer/>
      </BrowserRouter>
    );
  }
}

export default connect(mapStateToProps, actions)(ConnectedApp);
