import { library, dom } from '@fortawesome/fontawesome-svg-core'

import {fal} from '@fortawesome/pro-light-svg-icons'
import {far} from '@fortawesome/pro-regular-svg-icons'
import {fab} from '@fortawesome/free-brands-svg-icons'
import {fas} from '@fortawesome/pro-solid-svg-icons'

library.add(
  fas, fal, far, fab
)

dom.watch()
