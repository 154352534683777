import { createSlice } from '@reduxjs/toolkit'

export const projects = createSlice({
  name: "projects",
  initialState: {
      projects: []
  },
  reducers: {
    updateProjects: (state, action) => {
        return {
          projects: action.payload
        }
    }
  }
})

// Extract the action creators object and the reducer
const { actions, reducer } = projects
// Extract and export each action creator by name
export const { updateProjects } = actions
// Export the reducer, either as a default or named export
export default reducer
