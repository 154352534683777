import { createSlice } from '@reduxjs/toolkit'

export const files = createSlice({
  name: "files",
  initialState: { },
  reducers: {
    updateFiles: (state, action) => {
        // console.log("this is action.payload", action.payload)
        return  action.payload

    }
  }
})

// Extract the action creators object and the reducer
const { actions, reducer } = files
// Extract and export each action creator by name
export const { updateFiles } = actions
// Export the reducer, either as a default or named export
export default reducer
