import { createSlice } from '@reduxjs/toolkit'

export const settings = createSlice({
  name: "settings",
  initialState: {
      toggleAside: true
  },
  reducers: {
    toggleAside(state, action) {
      state.toggleAside = !state.toggleAside
    },
  }
})

// Extract the action creators object and the reducer
const { actions, reducer } = settings
// Extract and export each action creator by name
export const { toggleAside } = actions
// Export the reducer, either as a default or named export
export default reducer
